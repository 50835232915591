<template>
  <LoginBox @submit="onLoginSubmit">
    <el-row slot="title" type="flex" justify="center">运营管理系统</el-row>
    <div slot="foot">
      <el-link
        class="xa-mgt-8"
        type="primary"
        :href="'https://passport.xag.cn/#/login?goto=' + curUrl"
      >
        忘记密码？
      </el-link>
    </div>
  </LoginBox>
</template>
<script>
import { LoginBox } from 'xa-login'
import { Login } from '@target/apis/user'
export default {
  name: 'login',
  components: {
    LoginBox
  },
  data() {
    return {
      curUrl: window.encodeURIComponent(
        window.location.origin + window.location.pathname + `#/home/welcome`
      )
    }
  },
  methods: {
    async onLoginSubmit(values, cb) {
      try {
        await Login(values)
        this.afterLogin()
      } catch (error) {
        this.$message.error(error.msg || error.message)
      }
      cb()
      this.loading = false
    },
    afterLogin() {
      let defaultUrl = '/home/Welcome'
      const hash = window.location.hash
      let url = defaultUrl
      let index = hash.indexOf('goto=')
      if (index >= 0) {
        // 回调地址放在url上"http://192.168.11.130:97/pm/#!/login?goto=/agri/report",key是"goto="
        url = hash.substr(index + 5)
        if (!url || url.length < 2) {
          url = defaultUrl
        }
        url = decodeURIComponent(url).replace(/&amp;/g, '&')
      }
      if (url.indexOf('http') > -1 || url.indexOf('#') > -1) {
        this.$gotoUrl(url)
      } else {
        this.$router.replace(url)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login-wapper {
  background-image: url('../assets/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
